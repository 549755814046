<template>
  <span>
    <b-button type="is-primary" @click="click" native-type="button"><slot></slot></b-button>
    <div v-if="!packages && showFileName && filename">{{filename}}</div>
    <div v-if="packages && showFileName && filename" class="relative">
      <div class="absolute" :style="!isMobile ? 'top: 0px; left: -210px; padding-top: 20px;' : 'top: 0px; left: -250px; padding-top: 20px;'">
        <div class="flex">
          <div><b-icon icon="image-outline"></b-icon></div>
          <div>{{filename}}</div>
        </div>
      </div>
    </div>
    <input id="files" type="file" name="File" class="hidden" ref="fileinput" @change="inputChange" :accept="accept">
    <b-modal v-model="showCameraOption" has-modal-card can-cancel>
      <div class="modal-card bg-white" style="width: auto">
        <button type="button" class="p-5 text-center border-b" @click="selectImageCordova('camera')">
          Take photo
        </button>
        <button type="button" class="p-5 text-center" @click="selectImageCordova('gallery')">
          Select from gallery
        </button>
      </div>
    </b-modal>
    <preview-image v-if="previewImage" :img="value" v-model="preview">
      <template #footer>
        <div class="p-4">
          <b-button expanded type="is-primary" @click="upload()">Upload</b-button>
        </div>
      </template>
    </preview-image>
  </span>
</template>

<script>
import PreviewImage from './PreviewImage'
const base64Prefix = 'data:image/jpeg;base64,'
export default {
  components: {
    PreviewImage
  },
  props: {
    accept: {
      type: String,
      required: false
    },
    previewImage: {
        type: Boolean,
        default: true
    },
    showFileName: {
      type: Boolean,
      default: true
    },
    responseType: {
      type: String,
      default: 'base64'
    },
    packages: {
        type: Boolean,
        default: false
    }
  },
  data () {
    return {
      files: 0,
      value: null,
      showCameraOption: false,
      preview: false,
      file: null,
      filename: '',
      uploadFile: {}
    }
  },
  methods: {
    click () {
      if (this.isCordova) {
        this.showCameraOption = true
      } else {
        this.$refs.fileinput.click()
      }
    },
    selectImageCordova (type = 'camera') {
      this.showCameraOption = false
      if (this.isCordova) {
        this.$store.dispatch('modal/open', { id: 'camera' })
        setTimeout(() => {
          window.navigator.camera.getPicture((img) => {
            this.viewImage(base64Prefix + img)
          }, (err) => {
            alert(err)
          }, {
            sourceType: type === 'camera' ? 1 : 0,
            destinationType: 0
          })
        }, 100)
      }
    },
    inputChange (e) {
      if (!e.target.files.length) return
      const file = e.target.files[0]
      this.file = file
      // this.filename = this.file.name
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.viewImage(reader.result)
        this.uploadFile = { File: reader.result, Extension: file.name.substr(file.name.lastIndexOf('.') + 1), Size: file.size }
        // this.$emit('upload', { File: reader.result, Extension: file.name.substr(file.name.lastIndexOf('.') + 1), Size: file.size })
      }
      document.getElementById('files').value = null
    },
    viewImage (img) {
      this.value = img
      this.file = img
      if (this.isCordova) this.$emit('upload', { File: this.file, Extension: 'jpeg' })
      if (this.previewImage) {
        this.preview = true
      } else {
        this.upload()
      }
    },
    closeModal () {
      this.preview = false
    },
    async upload () {
        this.preview = false
        this.filename = Math.random().toString().substr(2, 6) + '.jpeg'
        if (!this.isCordova) this.$emit('upload', this.uploadFile)
    }
  }
}
</script>

<style>

</style>
