<template>
  <modal
    v-bind="modalData"
    @close="close"
  >
    <form @submit.prevent="submit" id="form-field">
      <page :data="pageData" :is-child="true" :is-modal="true">
        <template #cmsleftcomponent="{data: d}">
            <all-field :data="d.Data" @error="setErrors"></all-field>
            <div class="flex-col md:flex w-full justify-end">
              <!-- <b-button v-if="isMd" class="mr-2" @click="close">{{d.CloseButton || "Close"}}</b-button> -->
              <b-button class="w-full md:w-auto" type="is-primary" native-type="submit" :disabled="hasError">{{d.SubmitButton || "Save"}}</b-button>
            </div>
        </template>
      </page>
    </form>
  </modal>
</template>

<script>
import BaseFormPageVue from './BaseFormPage.vue'
import ModalCMSPage from './ModalCMSPage'
export default {
  extends: ModalCMSPage,
  mixins: [BaseFormPageVue],
  components: {
    AllField: () => import('@/components/form/AllField')
  },
  methods: {
    async submit () {
      this.$store.commit('service/SET_CONDITION_NOTIF', true)
      const loading = this.$buefy.loading.open()
      try {
        const form = document.getElementById('form-field')
        let data
        if (form.querySelector('input[type="file"]')) {
          data = new FormData(form)
        } else {
          data = this.getData('form-field')
        }
        await this.$baseApi.post(this.apiPath, data)
        this.close()
      } catch (err) {
        this.handleSubmitError(err)
      } finally {
        loading.close()
      }
    }
  },
  computed: {
    config () {
      return {
        ...this.response.Config || {},
        SubmitButton: false,
        CloseButton: false
      }
    }
  }
}
</script>

<style>

</style>
