<template>
  <b-modal :active="value" @close="close" :full-screen="isSm">
      <div class="w-full flex bg-white pt-4 pb-8">
        <div class="flex-1 flex flex-col">
          <div class="px-4">
            <b-button v-if="downloadable" icon-left="download" class="icon-button" @click="download"></b-button>
            <b-button class="invisible"></b-button>
          </div>
          <div class="flex-1 flex justify-center items-center">
            <img :src="img" class="w-full object-contain" :style="imgStyle" />
          </div>
          <slot name="footer">

          </slot>
        </div>
      </div>
    </b-modal>
</template>

<script>
export default {
  props: {
    value: Boolean,
    img: String,
    downloadable: Boolean
  },
  methods: {
    close () {
      this.$emit('input', false)
    },
    download () {
      if (this.isCordova) {
        window.cordova.plugins.DownloadManager.download(this.img, this.img.split('/').pop(), 'Download image', () => {}, (error) => alert(error), { setDestinationInExternalPublicDir: true })
      } else {
        window.open(this.img, '_blank')
      }
    }
  },
  computed: {
    imgStyle () {
      if (this.isSm) {
        return 'height: calc(100vh * .7)'
      }
      return 'height: calc(100vh * .5)'
    }
  }
}
</script>

<style>

</style>
