<script>
export default {
  components: {
    AllField: () => import('@/components/form/AllField')
  },
  data () {
    return {
      errors: {}
    }
  },
  methods: {
    handleSubmitError (err) {
      if (err.errors) {
        this.errors = err.errors
      } else {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: err.message,
          type: 'is-danger'
        })
      }
    },
    setErrors (errors) {
      console.log(errors)
      this.errors = errors
    }
  },
  computed: {
    hasError () {
//      console.log(Object.keys(this.errors).reduce((error, key) => {
 //       if (error) return error
   //     return Boolean(this.errors[key])
  //    }, false))
      return Object.keys(this.errors).reduce((error, key) => {
        if (error) return error
        return Boolean(this.errors[key])
      }, false)
    }
  }
}
</script>

<style>

</style>
