<template>
  <modal
    v-bind="modalData"
    class="modal-bg-gray"
    @close="close"
    :title="pageData.cmsLeft.Header.Title"
  >
    <form @submit.prevent="submit" id="form-field">
      <page :data="pageData" :is-child="true" :is-modal="true">
        <template #beforecmsleftcomponents>
          <div>
            <div class="flex mb-4">
              <div>
                <b-icon icon="archive-outline" style="color: #DF4545;" size="is-medium"></b-icon>
              </div>
              <div class="mt-1 ml-1">
                <span class="font-bold" style="color: #DF4545;">Detail Barang</span>
              </div>
            </div>
            <div class="flex pb-6 border-b border-red-500 mb-4">
              <div class="flex-1 font-bold">
                <div>Upload Invoice</div>
              </div>
              <div class="text-right">
                <upload-button @upload="setFile" :packages="true">Upload</upload-button>
              </div>
            </div>
          </div>
        </template>
        <template #cmsleftcomponent="{data: d}">
            <all-field :data="d.Data" @error="setErrors"></all-field>
            <div class="border-t border-b border-red-500 mt-4">
              <p><i>*Upload invoice / isi berdasarkan detail paket kiriman. Segala resiko yang terjadi akibat dari kesalahan pengisian merupakan tanggung jawab customer</i></p>
            </div>
            <div class="flex-col md:flex w-full justify-end mt-8">
              <b-button class="w-full md:w-auto" type="is-primary" native-type="submit" @click="close()" :disabled="hasError">{{d.SubmitButton || "Kirim"}}</b-button>
            </div>
        </template>
        <!-- <template #aftercmsleftcomponents>
          <div class="border-t border-b border-red-500">
            <p>*Upload invoice / isi berdasarkan detail paket kiriman. Segala resiko yang terjadi akibat dari kesalahan pengisian merupakan tanggung jawab customer</p>
          </div>
        </template> -->
      </page>
    </form>
  </modal>
</template>
<script>
import ModalFormPage from '../../base/ModalFormPage'
import UploadButton from '@/components/vaddress/UploadButton'

export default {
  extends: ModalFormPage,
  components: { UploadButton },
  props: {
    shippingGoodTypeOptions: Array,
    initialValues: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
      return {
          initOnMounted: false,
          file: null
      }
  },
  methods: {
    submit () {
      const data = this.getData('form-field')
      if (this.hasError) return
      this.$emit('submit', { ...data, FeaturedFile: this.file })
      // this.close()
    },
    setFile (file) {
      this.file = file
    }
  },
  computed: {
    config () {
      return {
        ...this.pageData.Config || {},
        SubmitButton: false,
        CloseButton: false
      }
    },
    pageData () {
      const { initialValues = {} } = this
      return {
        config: {
          Header: {
            Title: 'Detail Barang'
          }
        },
        cmsLeft: {
          Header: {
              Title: 'Verifikasi Paket'
          },
          Data: [
            {
              Type: 'form',
              Data: [
                {
                  type: 'inputtext',
                  fieldToSave: 'SelfDeclareItem',
                  default: initialValues.SelfDeclareItem || '',
                  column: 'full',
                  overrideLabel: 'Nama Barang',
                  placeHolder: 'Nama Barang',
                  validationParams: 'required'
                },
                {
                  type: 'inputmoney',
                  fieldToSave: 'AmountGoodValue',
                  default: initialValues.AmountGoodValue || '',
                  column: 'full',
                  overrideLabel: 'Harga Barang',
                  placeHolder: 'Harga Barang',
                  validationParams: 'required',
                  showSymbol: false
                },
                {
                  type: 'popupoptions',
                  fieldToSave: 'ShippingGoodType',
                  default: initialValues.ShippingGoodType || '',
                  column: 'full',
                  overrideLabel: 'Kategori Paket',
                  placeHolder: 'Pilih Kategori Paket',
                  validationParams: 'required',
                  modal: 'CheckoutShippingGoodType',
                  source: this.shippingGoodTypeOptions
                }
              ]
            }
          ]
        }
      }
    }
  }
}
</script>

<style>

</style>
